// Notes on the classes:
//
// 1. The .carousel-item-left and .carousel-item-right is used to indicate where
//    the active slide is heading.
// 2. .active.carousel-item is the current slide.
// 3. .active.carousel-item-left and .active.carousel-item-right is the current
//    slide in its in-transition state. Only one of these occurs at a time.
// 4. .carousel-item-next.carousel-item-left and .carousel-item-prev.carousel-item-right
//    is the upcoming slide in transition.

.carousel {
  position: relative;
}

.modal-open {
  width: 102vw !important; /* Fix width to 100vw when modal is open */
}

.carousel-inner {
  position: relative;
  // width: 100%;
  width: 100vw;
  overflow: hidden;
}

.carousel-item {
  position: relative;
  display: none;
  align-items: center;
  width: 100%;
  backface-visibility: hidden;
  perspective: 1000px;
  height: 600px !important;
  min-height: 280px !important; /* 이미지의 최대 높이를 설정함 */
  max-height: 600px !important; /* 이미지의 최대 높이를 설정함 */
}

.carousel-content {
  position: relative;
}

.carousel-content img {
  width: 100%; /* 이미지 너비를 컨테이너 너비에 맞게 설정함 */
  height: 100%; /* 이미지의 높이를 자동으로 조정함 */
  object-fit: cover; /* 이미지를 컨테이너에 맞게 잘라내기 */
  height: 600px !important;
  min-height: 280px !important; /* 이미지의 최대 높이를 설정함 */
  max-height: 600px !important; /* 이미지의 최대 높이를 설정함 */
}

.carousel-caption-custom {
  position: absolute;
  bottom: 0;
  top: 20%;
  // left: 10%;
  right: calc((100% - #{$carousel-caption-width}) / 2);
//   bottom: 20px;
  left: calc((100% - #{$carousel-caption-width}) / 2);
  transform: translateX(-3%);
  font-size: 8em;
  font-family: 'GangwonEduPowerExtraBoldA';
  // font-family: 'TENADA';
  text-align: center;
  display: flex;
  justify-content: center;
  // background: rgba(0, 0, 0, 0.5);
  @media (max-width: 1280px) {
      font-size: 8rem;
  }

  @media (max-width: 1024px) {
      font-size: 6rem;
  }

  @media (max-width: 912px) {
      font-size: 5rem;
  }

  @media (max-width: 820px) {
      font-size: 5rem;
  }

  @media (max-width: 768px) {
      font-size: 4rem;
  }

  @media (max-width: 740px) {
      font-size: 3.5rem;
  }

  @media (max-width: 667px) {
      font-size: 3.5rem;
  }

  @media (max-width: 430px) {
      font-size: 3rem;
  }

  @media (max-width: 414px) {
      font-size: 3rem;
  }

  @media (max-width: 412px) {
      font-size: 3rem; 
  }
  
  @media (max-width: 390px) {
      font-size: 3rem;
  }

  @media (max-width: 375px) {
      font-size: 3rem;
  }

  @media (max-width: 360px) {
      font-size: 3rem;
  }

  @media (max-width: 280px) {
      font-size: 3rem;
  }
}

// .gradient-text {
//   color: transparent; /* 텍스트를 투명하게 만듭니다. */
//   /* 그라데이션을 배경으로 적용하고, 텍스트에만 적용하도록 설정합니다. */
//   // background: rgb(59,114,108);
//   // background: linear-gradient(to top, rgba(113,147,188,1) 86%, rgba(52,135,201,1) 95%);  
//   background: white;
//  -webkit-background-clip: text;
//  -webkit-text-fill-color: transparent;
//  -webkit-text-stroke-width: 1px;
//   // -webkit-text-stroke-color: #00285D;
//   -webkit-text-stroke-color: black;
// }

// .animated-char {
//   opacity: 0;
//   animation: slide-up 1s ease forwards;
// }

// @keyframes slide-up {
//   0% {
//     opacity: 0;
//     transform: translateY(100%);
//   }
//   100% {
//     opacity: 1;
//     transform: translateY(0);
//   }
// }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  @include transition($carousel-transition);
}

.carousel-item-next,
.carousel-item-prev {
  position: absolute;
  top: 0;
}

.carousel-item-next.carousel-item-left,
.carousel-item-prev.carousel-item-right {
  transform: translateX(0);

  @supports (transform-style: preserve-3d) {
    transform: translate3d(0, 0, 0);
  }
}

.carousel-item-next,
.active.carousel-item-right {
  transform: translateX(100%);

  @supports (transform-style: preserve-3d) {
    transform: translate3d(100%, 0, 0);
  }
}

.carousel-item-prev,
.active.carousel-item-left {
  transform: translateX(-100%);

  @supports (transform-style: preserve-3d) {
    transform: translate3d(-100%, 0, 0);
  }
}


//
// Alternate transitions
//

.carousel-fade {
  .carousel-item {
    opacity: 0;
    transition-duration: 1s;
    transition-property: opacity;
  }

  .carousel-item.active,
  .carousel-item-next.carousel-item-left,
  .carousel-item-prev.carousel-item-right {
    opacity: 1;
  }

  .active.carousel-item-left,
  .active.carousel-item-right {
    opacity: 0;
  }

  .carousel-item-next,
  .carousel-item-prev,
  .carousel-item.active,
  .active.carousel-item-left,
  .active.carousel-item-prev {
    transform: translateX(0);

    @supports (transform-style: preserve-3d) {
      transform: translate3d(0, 0, 0);
    }
  }
}


//
// Left/right controls for nav
//

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  // Use flex for alignment (1-3)
  display: flex; // 1. allow flex styles
  align-items: center; // 2. vertically center contents
  justify-content: center; // 3. horizontally center contents
  width: $carousel-control-width;
  color: $carousel-control-color;
  text-align: center;
  opacity: $carousel-control-opacity;
  // We can't have a transition here because WebKit cancels the carousel
  // animation if you trip this while in the middle of another animation.

  // Hover/focus state
  @include hover-focus {
    color: $carousel-control-color;
    text-decoration: none;
    outline: 0;
    opacity: .9;
  }
}
.carousel-control-prev {
  left: 0;
  @if $enable-gradients {
    background: linear-gradient(90deg, rgba($black, .25), rgba($black, .001));
  }
}
.carousel-control-next {
  right: 0;
  @if $enable-gradients {
    background: linear-gradient(270deg, rgba($black, .25), rgba($black, .001));
  }
}

// Icons for within
.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: $carousel-control-icon-width;
  height: $carousel-control-icon-width;
  background: transparent no-repeat center center;
  background-size: 100% 100%;
}
.carousel-control-prev-icon {
  background-image: $carousel-control-prev-icon-bg;
}
.carousel-control-next-icon {
  background-image: $carousel-control-next-icon-bg;
}


// Optional indicator pips
//
// Add an ordered list with the following class and add a list item for each
// slide your carousel holds.

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 10px;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0; // override <ol> default
  // Use the .carousel-control's width as margin so we don't overlay those
  margin-right: $carousel-control-width;
  margin-left: $carousel-control-width;
  list-style: none;

  li {
    position: relative;
    flex: 0 1 auto;
    width: $carousel-indicator-width;
    height: $carousel-indicator-height;
    margin-right: $carousel-indicator-spacer;
    margin-left: $carousel-indicator-spacer;
    text-indent: -999px;
    cursor: pointer;
    background-color: rgba($carousel-indicator-active-bg, .5);

    // Use pseudo classes to increase the hit area by 10px on top and bottom.
    &::before {
      position: absolute;
      top: -10px;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 10px;
      content: "";
    }
    &::after {
      position: absolute;
      bottom: -10px;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 10px;
      content: "";
    }
  }

  .active {
    background-color: $carousel-indicator-active-bg;
  }
}


.carousel-caption {
  position: absolute;
  bottom: 0;
  top: 10%;
  left: 10%;
  transform: translateX(-50%) translateY(100%);
  font-size: 8rem;
  font-family: GangwonEduPowerExtraBoldA;
  // text-align: center;
  display: flex;
  justify-content: center;
  animation: slide-up 1s ease forwards;
  text-shadow: 0 0 5px black; /* 텍스트 테두리에 검정색 그림자 추가 */
  @media (max-width: 1280px) {
    font-size: 6rem;
  }

  @media (max-width: 1024px) {
      font-size: 6rem;
  }

  @media (max-width: 912px) {
      font-size: 5rem;
  }

  @media (max-width: 820px) {
      font-size: 5rem;
  }

  @media (max-width: 768px) {
      font-size: 4rem;
  }

  @media (max-width: 740px) {
      font-size: 3.5rem;
  }

  @media (max-width: 667px) {
      font-size: 3.5rem;
  }

  @media (max-width: 430px) {
      font-size: 3rem;
  }

  @media (max-width: 414px) {
      font-size: 3rem;
  }

  @media (max-width: 412px) {
      font-size: 3rem; 
  }

  @media (max-width: 390px) {
      font-size: 3rem;
  }

  @media (max-width: 375px) {
      font-size: 3rem;
  }

  @media (max-width: 360px) {
      font-size: 3rem;
  }

  @media (max-width: 280px) {
      font-size: 3rem;
  }
}

.animated-char {
  opacity: 0;
  animation: slide-up 1.8s ease forwards;
  transform: translateY(100%);
}

@keyframes slide-up {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}


// .carousel-caption h1 {
//   font-size: 6rem;
//   font-family: GangwonEduPowerExtraBoldA;
// }


// /* ProductCarousel.css */
// .koreatau-carousel .carousel-inner {
//   position: relative;
// }

// .koreatau-carousel .div_tablet2,
// .koreatau-carousel .div_mobile2 {
//   position: absolute;
//   top: 50%;
//   transform: translateY(-50%);
//   right: 0;
// }

// .koreatau-carousel .div_mobile2 {
//   margin-top: -33px;
//   margin-left: -50px;
// }

// .koreatau-carousel .koreatau-prev,
// .koreatau-carousel .koreatau-next {
//   cursor: pointer;
// }

.custom-banner-ca .carousel-item img {
  width: 100%;
  height: 600px;
  overflow: hidden;
}

.carousel-item-cs.active {
  transition: transform 0.4s ease;
  height: 500px !important;
}

.custom-carousel-caption1 {
  position: absolute;
  width: fit-content;
  top: -40%;
  // left: -20% !important;
  // right: 20% !important; /* 수정: 적절한 값으로 조정 */
  padding: 20px; /* 적절한 여백 설정 */
  display: flex; /* 요소들을 행으로 배치하기 위해 Flexbox 사용 */
  flex-direction: column; /* 요소들을 세로로 배치 */
}


.custom-carousel-caption2 {
  // justify-items: right;
  position: absolute;
  width: fit-content;
  top: 70%;
  // left: 20% !important;
  left: 45% !important; /* 수정: 적절한 값으로 조정 */
  padding: 20px; /* 적절한 여백 설정 */
  display: flex; /* 요소들을 행으로 배치하기 위해 Flexbox 사용 */
  flex-direction: column; /* 요소들을 세로로 배치 */
}

.card-font-ca {
    font-size: 3rem !important;
    color: white !important;
    @media (max-width: 1280px) {
        font-size: 3rem !important;
    }

    @media (max-width: 1024px) {
        font-size: 3rem !important;
    }

    @media (max-width: 912px) {
        font-size: 3rem !important;
    }

    @media (max-width: 853px) {
        font-size: 3rem !important;
        // line-height: 3rem;
    }

    @media (max-width: 820px) {
        font-size: 3rem !important;
    }

    @media (max-width: 768px) {
        font-size: 2.5rem !important;
    }

    @media (max-width: 740px) {
        font-size: 2.5rem !important;
    }

    @media (max-width: 667px) {
        font-size: 2.5rem !important;
    }

    @media (max-width: 430px) {
        font-size: 2.5rem !important;
    }

    @media (max-width: 414px) {
        font-size: 2.5rem !important;
    }

    @media (max-width: 412px) {
        font-size: 2.5rem !important;
    }

    @media (max-width: 390px) {
        font-size: 2rem !important;
    }

    @media (max-width: 375px) {
        font-size: 2rem !important;
    }

    @media (max-width: 360px) {
        font-size: 2rem !important;
    }

    @media (max-width: 280px) {
        font-size: 2rem !important;
    }
}