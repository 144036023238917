// stylelint-disable declaration-no-important

@each $color, $value in $theme-colors {
  @include bg-variant(".bg-#{$color}", $value);
}

@if $enable-gradients {
  @each $color, $value in $theme-colors {
    @include bg-gradient-variant(".bg-gradient-#{$color}", $value);
  }
}

.bg-white {
  background-color: $white !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.bg-test {
  background-color: #3B726C;
  // background-color: #4DABA2;
  // background-color: #00B5FF
  // background-color: #36C190;
  background-color: #3487C9;
}