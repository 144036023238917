//
// Base styles
//

.background2 {
  background-color: #D8F3FF !important;
  // border: 5px solid #00B1DF !important;
  background-image: linear-gradient(309deg, rgba(215,241,249,0.6) 18%, rgba(176,225,251,0.9) 44%, rgba(139,193,230,1) 66%, rgba(100,167,217,1) 88%), url("../../images/portfolio/product2.jpg");
  // background-repeat: no-repeat;
  // background-size: cover;
}

.background1 {
  // background-color: #FFEDCB !important;
  // background-color: #E6F4F1 !important;
  background-color: #F3EADA !important;
  // border: 5px solid #00B1DF !important;
  background-image: linear-gradient(309deg, rgba(243,234,218,0.5) 18%, rgba(238,232,218,0.8) 44%, rgba(235,230,218,1) 66%, rgba(235,230,218,1) 88%), url("../../images/portfolio/product1.jpg");
  // background-repeat: no-repeat;
  // background-size: cover;
}

.background3 {
  // background-color: #FFEDCB !important;
  background-color: #e6f7ee!important;
  // background-color: #F3EADA !important;
  // border: 5px solid #00B1DF !important;
  background-image: linear-gradient(309deg, rgba(230,247,238,0.6) 18%, rgba(227,247,230,0.9) 44%, rgba(233,250,236,1) 66%, rgba(231,243,233,1) 88%), url("../../images/portfolio/product3.jpg");
  // background-repeat: no-repeat;
  // background-size: cover;
}

.cards {
  padding: 20px;
 }
 .card {
   width: 100%;
   height: 100%;
  //  margin-right: 15px;
   position: relative;
   float: left;
   perspective: 1000px; /*3_카드가 돌아갈때 원근법을 주기 위해-앞 뒷면 돌아가는 걸 보기 위해 부모에게 적용*/
 }
 .card:last-child{
   margin-right: 0;
 }
 .card .face {
   width: 150px;
   height: 220px;
   border:1px solid lightgray;
   border-radius: 10px;
   display:flex;
   justify-content: center;
   align-items: center;
   transition:1s;
   position: absolute;  
   backface-visibility:hidden; /*1_로고의 뒷면이나, 뒷카드의 앞면 둘다 숨겨져 있어야 함.*/
   background-color: #fff;
 }
 .card .face-front {
   transform: rotateY(0deg);
  //  background-image: url("https://heropy.blog/css/images/pattern.png");
 }
 .card:hover .face-front{
   transform: rotateY(-180deg);
 }
 .card .face-back {
   transform: rotateY(180deg);/*2_뒷카드는 뒤집혀있어야 하므로 Y축으로 180도 돌려서 안보이게 처리*/
 }
 .card:hover .face-back{
   transform: rotateY(0deg);
 }

 .card:hover .face-front {
  transform: rotateY(-180deg);
}

.card:hover .face-back {
  transform: rotateY(0deg);
}
 
 .card .face-front img{
   width: 90px;
  //  filter:grayscale();
   opacity:.7;
 }
 .card .face-back img{
   height: 120px;
 }


.bg-custom {
  background-color: #3487C9;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  flex-direction: row;
}

.card-flip-horizontal {
  transition: transform 3s ease !important;
}

.parent{
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  z-index: 0;
  padding-bottom: 2%;
  background-color: rgba(0, 56, 177, 0.801);

  @media (max-width: 1280px) {
    flex-direction: row;
  }

  @media (max-width: 1024px) {
    flex-direction: row;
  }

  @media (max-width: 912px) {
    flex-direction: column;
  }

  @media (max-width: 820px) {
    flex-direction: column;
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }

  @media (max-width: 740px) {
    flex-direction: column;
  }

  @media (max-width: 667px) {
    flex-direction: column;
  }

  @media (max-width: 430px) {
    flex-direction: column;
  }

  @media (max-width: 414px) {
    flex-direction: column;
  }

  @media (max-width: 412px) {
    flex-direction: column;
  }
  
  @media (max-width: 390px) {
    flex-direction: column;
  }

  @media (max-width: 375px) {
    flex-direction: column;
  }

  @media (max-width: 360px) {
    flex-direction: column;
  }

  @media (max-width: 280px) {
    flex-direction: column;
  }


}

.first {
  // float: left;
  margin: 2%;
  display: flex;
  width: 30%;
  flex: 1;
  box-sizing: border-box;
  z-index: 1;
  overflow: hidden;
  padding: 1%;
}

.second{
  // float: left;
  margin: 2%;
  display: flex;
  padding: 1%;
  width:30%;
  flex:1;
  box-sizing: border-box;
  overflow: hidden;
}

.third{
  // float: left;
  margin: 2%;
  padding: 1%;
  display: flex;
  width: 30%;
  flex:1;
  box-sizing: border-box;
  overflow: hidden;
}

.card {
  position: relative;
  flex-direction: row;
  // min-height: 500px;
  word-wrap: break-word;
  // background-color: $card-bg;
  background-clip: border-box;
  border: $card-border-width solid $card-border-color;
  @include border-radius($card-border-radius);

  > hr {
    margin-right: 0;
    margin-left: 0;
  }

  > .list-group:first-child {
    .list-group-item:first-child {
      @include border-top-radius($card-border-radius);
    }
  }

  > .list-group:last-child {
    .list-group-item:last-child {
      @include border-bottom-radius($card-border-radius);
    }
  }

}



// .card-custom {
//     // opacity: 100%;
//     img {
//       position: absolute;
//             top: 0;
//             left: 0;
//             width: 100%;
//             height: 100%;
//     }

// }

.card-body {
  // Enable `flex-grow: 1` for decks and groups so that card blocks take up
  // as much space as possible, ensuring footers are aligned to the bottom.
  flex: 1 1 auto;
  padding: $card-spacer-x;
  // background-color: rgb(71, 70, 70);

  h3 {
      color : white;
      display: flow-root;
  };

  p {
    color : white;
    display: flow-root;
  };

}


// .card-test:hover {
//   transform: rotateY(180deg); /* 호버 시 180도 회전하여 뒤집히는 효과 */
// }

.card-test {
  position: relative;
  // background-color: rgb(0, 0, 0);
  width: 100%;
  height: 100%;
  perspective: 1000px;
  margin : 5% auto;
  // margin-left : 10px;
  // opacity: 78%;
  // max-width: 698px;
  // background-size: 50%;
  perspective: 1000px; /* 3D 효과를 위한 원근 거리 설정 */
  // transition: transform 0.5s; /* 변환 애니메이션 속성 설정 */
  overflow: hidden;
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    margin: 0 auto;
    background-color: white;
    padding: 1%;
    border-radius: 20px;
  }


  .card-inner {
    width: 100%;
    height: 100%;
    transition: transform 3s;
    transform-style: preserve-3d;
  }

  .card-hover:hover .card-inner {
    transform: rotateY(180deg);
}

.card-hover:hover .card-test.flip .card-inner {
    transform: rotateY(180deg);
}
  
  // .card-test.flip .card-inner {
  //   transform: rotateY(180deg);
  // }

  // .card-test:hover .card-inner {
  //   transform: rotateY(180deg);
  // }

  // .card-test.flip .card-back {
  //   visibility: visible; /* 뒷면이 보일 때 뒷면을 보이도록 설정 */
  // }
  
  // .card-test.flip .card-back {
  //   transform: rotateY(-180deg); /* 뒷면이 보일 때 뒤집기 효과 */
  // }


  // @media (max-width: 1280px) {
  //   width: 820px;
  // }

  // @media (max-width: 1024px) {
  //   width: 696px;
  // }

  // @media (max-width: 820px) {
  //   width: 820px;
  // }

  // @media (max-width: 768px) {
  //   width: 768px;
  // }

  // @media (max-width: 740px) {
  //   width: 740px;
  // }

  // @media (max-width: 667px) {
  //   width: 667px;
  // }

  // @media (max-width: 430px) {
  //   width: 430px;
  // }

  // @media (max-width: 414px) {
  //   width: 414px;
  // }

  // @media (max-width: 412px) {
  //   width: 412px;
  // }
  
  // @media (max-width: 390px) {
  //   width: 390px;
  // }

  // @media (max-width: 375px) {
  //   width: 375px;
  // }

  // @media (max-width: 360px) {
  //   width: 360px;
  // }

}

.card-title {
  margin-bottom: #{$card-spacer-y};
  display: flow-root;
}

.card-subtitle {
  margin-top: calc(#{$card-spacer-y} / 2);
  margin-bottom: 0;
  display: flow-root;
}

.card-text:last-child {
  margin-bottom: 0;
  text-align: center;
  display: flow-root;
}

.card-link {
  @include hover {
    text-decoration: none;
  }

  + .card-link {
    margin-left: $card-spacer-x;
  }
}

//
// Optional textual caps
//

.card-header {
  padding: $card-spacer-y $card-spacer-x;
  margin-bottom: 0; // Removes the default margin-bottom of <hN>
  background-color: $card-cap-bg;
  border-bottom: $card-border-width solid $card-border-color;
  display: flow-root;

  &:first-child {
    @include border-radius($card-inner-border-radius $card-inner-border-radius 0 0);
  }

  + .list-group {
    .list-group-item:first-child {
      border-top: 0;
    }
  }
}

.card-footer {
  padding: $card-spacer-y $card-spacer-x;
  background-color: $card-cap-bg;
  border-top: $card-border-width solid $card-border-color;
  display: flow-root;

  &:last-child {
    @include border-radius(0 0 $card-inner-border-radius $card-inner-border-radius);
  }
}


//
// Header navs
//

.card-header-tabs {
  margin-right: calc(#{$card-spacer-x} / 2);
  margin-bottom: -#{$card-spacer-y};
  margin-left: calc(#{$card-spacer-x} / 2);
  border-bottom: 0;
}

.card-header-pills {
  margin-right: calc(#{$card-spacer-x}/ 2);
  margin-left: calc(#{$card-spacer-x}/ 2);
}

// Card image
.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: $card-img-overlay-padding;
}

.card-img {
  width: 100%; // Required because we use flexbox and this inherently applies align-self: stretch
  @include border-radius($card-inner-border-radius);
}

// Card image caps
.card-img-top {
  width: 100%; // Required because we use flexbox and this inherently applies align-self: stretch
  @include border-top-radius($card-inner-border-radius);
}

.card-img-bottom {
  width: 100%; // Required because we use flexbox and this inherently applies align-self: stretch
  
  @include border-bottom-radius($card-inner-border-radius);
  position: relative; /* Add relative positioning */
  overflow: hidden; /* Ensure the image stays within its container */
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; /* Set image to cover its container */
    overflow: hidden;
  }
}


// Card deck

.card-deck {
  display: flex;
  flex-direction: column;

  .card {
    margin-bottom: $card-deck-margin;
  }

  @include media-breakpoint-up(sm) {
    flex-flow: row wrap;
    margin-right: -$card-deck-margin;
    margin-left: -$card-deck-margin;

    .card {
      display: flex;
      // Flexbugs #4: https://github.com/philipwalton/flexbugs#flexbug-4
      flex: 1 0 0%;
      flex-direction: column;
      margin-right: $card-deck-margin;
      margin-bottom: 0; // Override the default
      margin-left: $card-deck-margin;
    }
  }
}


//
// Card groups
//

.card-group {
  display: flex;
  flex-direction: column;

  // The child selector allows nested `.card` within `.card-group`
  // to display properly.
  > .card {
    margin-bottom: $card-group-margin;
  }

  @include media-breakpoint-up(sm) {
    flex-flow: row wrap;
    // The child selector allows nested `.card` within `.card-group`
    // to display properly.
    > .card {
      // Flexbugs #4: https://github.com/philipwalton/flexbugs#flexbug-4
      flex: 1 0 0%;
      margin-bottom: 0;

      + .card {
        margin-left: 0;
        border-left: 0;
      }

      // Handle rounded corners
      @if $enable-rounded {
        &:first-child {
          @include border-right-radius(0);

          .card-img-top,
          .card-header {
            border-top-right-radius: 0;
          }
          .card-img-bottom,
          .card-footer {
            border-bottom-right-radius: 0;
          }
        }

        &:last-child {
          @include border-left-radius(0);

          .card-img-top,
          .card-header {
            border-top-left-radius: 0;
          }
          .card-img-bottom,
          .card-footer {
            border-bottom-left-radius: 0;
          }
        }

        &:only-child {
          @include border-radius($card-border-radius);

          .card-img-top,
          .card-header {
            @include border-top-radius($card-border-radius);
          }
          .card-img-bottom,
          .card-footer {
            @include border-bottom-radius($card-border-radius);
          }
        }

        &:not(:first-child):not(:last-child):not(:only-child) {
          @include border-radius(0);

          .card-img-top,
          .card-img-bottom,
          .card-header,
          .card-footer {
            @include border-radius(0);
          }
        }
      }
    }
  }
}


//
// Columns
//

.card-columns {
  .card {
    margin-bottom: $card-columns-margin;
  }

  @include media-breakpoint-up(sm) {
    column-count: $card-columns-count;
    column-gap: $card-columns-gap;
    orphans: 1;
    widows: 1;

    .card {
      display: inline-block; // Don't let them vertically span multiple columns
      width: 100%; // Don't let their width change
    }
  }
}


//
// Accordion
//

.accordion {
  .card:not(:first-of-type):not(:last-of-type) {
    border-bottom: 0;
    border-radius: 0;
  }

  .card:not(:first-of-type) {
    .card-header:first-child {
      border-radius: 0;
    }
  }

  .card:first-of-type {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .card:last-of-type {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}
