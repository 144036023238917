/* global styles for orgchart */
.orgchart-container {
    position: relative;
    margin: 10px;
    height: 700px;
    overflow: auto;
    text-align: center;
  }
  
  .orgchart {
    /* box-sizing: border-box; */
    display: inline-block;
    /* background-image: linear-gradient(90deg, rgba(200, 0, 0, 0.15) 10%, rgba(0, 0, 0, 0) 10%), linear-gradient(rgba(200, 0, 0, 0.15) 10%, rgba(0, 0, 0, 0) 10%);
    background-size: 10px 10px;
    border: 1px dashed rgba(0,0,0,0); */
    padding: 20px 20px 0 20px;
  }
  
  .orgchart-container .hidden {
    display: none!important;
  }
  
  .orgchart-container > .oc-mask {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 999;
    text-align: center;
    background-color: rgba(0,0,0,0.3);
  }
  
  .orgchart-container > .oc-mask .spinner {
    position: absolute;
    top: calc(50% - 50px);
    left: calc(50% - 50px);
  }
  
  .orgchart-container > .oc-mask .spinner::before {
    width: 100px;
    height: 100px;
    border-width: 10px;
    border-radius: 50px;
    border-top-color: rgba(68, 157, 68, 0.8);
    border-bottom-color: rgba(68, 157, 68, 0.8);
    border-left-color: rgba(68, 157, 68, 0.8);
  }

/* 스크롤 바를 감춥니다. */
.orgchart-container::-webkit-scrollbar {
  height: 10px !important;
  /* background: rgba(255, 255, 255, 0.14) !important; 바탕 배경을 투명하게 설정 */
  background: transparent !important;
}

/* 스크롤 thumb을 스타일링합니다. */
.orgchart-container::-webkit-scrollbar-thumb {
  background-color: #76BCFF !important; /* 스크롤 thumb의 배경색 */
  border-radius: 10px !important; /* 스크롤 thumb의 모양을 둥글게 만듭니다. */
}

/* 스크롤 thumb에 호버 효과를 추가합니다. */
.orgchart-container::-webkit-scrollbar-thumb:hover {
  background-color: #3487C9 !important; /* 호버 했을 때 thumb의 색상을 변경합니다. */
}
